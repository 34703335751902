.highlight {
    background-color: yellow;
  }
.FormStyle{}
.selectForm{
  margin-top: 40px;
  height: 40px;
  margin-bottom: 60px;
}
#ButtonGenerate{
  margin-top: 10px;
  height: 60px;
  width:300px;
  background-color: red;
}
#headButtons{
  display: flex;
  flex-direction: row;
  align-items: center;
}
#authBlick{
  display: flex;
  flex-direction: column;
  margin-left: 100px;

}

.InputSection{
  display:grid;
  grid-template-columns: repeat(8,minmax(190px,3px));
  row-gap: 8px;
  column-gap: 10px;
  margin-bottom: 50px;
  background-color: rgb(221, 157, 157);
}
.InputSmallSection{
  display:grid;
  grid-template-columns: repeat(8,minmax(190px,3px));
  row-gap: 8px;
  column-gap: 10px;
  margin-bottom: 50px;
  background-color: lightgreen;
}



/* .FormStyle :nth-child(-n+20){
  
}

.FormStyle :nth-child(n+21):nth-child(-n+32){

}

.FormStyle :nth-child(n+33):nth-child(-n+40){

}

.FormStyle :nth-child(n+41):nth-child(-n+58){

}

.FormStyle :nth-child(n+59){
  
}  */